//export const API_URL = "https://dev-backend.alldamnlife.com/api";
//export const API_URL = "http://192.168.1.91:9740/api";
//export const API_URL = "http://localhost:9740/api";

import { config } from 'dotenv';
config();

export const API_URL = process.env.REACT_APP_BACK_URL;
export const CHECK_SEUIL = `${API_URL}/auth/seuil`;
export const UNSUSCRIBE = `${API_URL}/auth/unsuscribe`;
export const SIGNUP_API = `${API_URL}/auth/signup`;
export const LOGIN_API = `${API_URL}/auth/signin`;
export const GOOGLE_API = `${API_URL}/auth/oauth/google`;
export const FACEBOOK_API = `${API_URL}/auth/oauth/facebook`;
export const FORGOT_API = `${API_URL}/auth/forgot_password`;
export const RESET_PASSWORD_API = `${API_URL}/auth/reset_password`;
export const NEW_TOKEN = `${API_URL}/auth/token/new`;
export const TOKEN_API = `${API_URL}/auth/activate`;
export const RESEND_ACTIVATION_EMAIL_API = `${TOKEN_API}/resend`;
export const CHECK_TOKEN_VALIDITY = `${API_URL}/auth/token/check`;

// With authentication
export const DASHBOARD_API_URL = `${API_URL}/auth/dashboard`;
export const PACKS_API_URL = `${API_URL}/packs`;
export const USERS_API_URL = `${API_URL}/users`;
export const ACTIVATION_CODE_API_URL = `${API_URL}/activation-codes`;
export const SRVCS_API_URL = `${API_URL}/services`;
export const VAULT_API_URL = `${API_URL}/coffre`;
export const AUTOMATE_API_URL = `${API_URL}/automate`;
export const PAIEMENT_API_URL = `${API_URL}/stripe`;
export const LOGO_API_URL = '//logo.clearbit.com/';
export const SOUSCRIPTION_API_URL = `${API_URL}/souscriptions`;

export const STRIPE_PUB_KEY = "pk_test_PUmgyIClt5X1AxATspdt4SvC001PL3RUqm";
